@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('fonts/Inter-Regular.woff2') format('woff2'),
        url('fonts/Inter-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('fonts/Inter-Bold.woff2?v=3.13') format('woff2'),
        url('fonts/Inter-Bold.woff?v=3.13') format('woff');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
        url('fonts/SpaceGrotesk-Bold.woff') format('woff');
}
